/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/bootstrap-extended/variables';

/*
*	INDEX
* 1. SPACEMENTS
* 2. BUTTONS
* 3. TEXTS
* 4. CONTAINERS/SECTIONS
* 5. COMPONENTS
*/

// 1. SPACEMENTS
.mb-30 {
	margin-bottom: 30px;
}

.mb-32 {
	margin-bottom: 2rem;
}

.mt-32 {
	margin-top: 2rem;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-76 {
	margin-bottom: 76px;
}

.py-32 {
	padding-top: 32px;
	padding-bottom: 32px;
}

// 2. BUTTONS

.btn-link {
	background: none;
	border: none;

	&:hover {
		text-decoration: underline;
	}
}

.text-btn {
	border: none;
	background: none;
	transition: .2s;

	&:hover {
		opacity: .6;
	}
}

.btn-form-size {
	max-width: 190px;
	width: 100%;
}

.btn-default-size {
	max-width: 144px;
	width: 100%;
}

.btn-lg {
	height: 47px;
	font-size: 14px;
}

.btn-flat-success {
	background-color: rgba($success, 0.12);

	transition: .2s;

	&:hover {
		background-color: transparent !important;
	}
}

.paragraph-link {
	text-decoration: underline;
	color: $headings-color;

	&:hover {
		text-decoration: none;
	}
}

// 3. TEXTS
.text-headings {
	color: $headings-color;
}

.text-body-color {
	color: $body-color;
}

.text-theme-light {
	color: $gray-100;
}

.text-overflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

// 4. CONTAINERS/SECTIONS

.theme-container {
	padding: 0 2.25rem;
    padding-top: 48px;
}

.default-section-pt {
    padding-top: 40px;
}

.white-content {
	background-color: $white;
}

.full-screen {
	min-height: 100vh !important;
	background-color: $body-bg;
}

@media(max-width: 991px) {
	.full-screen {
		height: unset;
	}
	.theme-container {
		padding: 0;
	}
}

// 5. COMPONENTS

.card {
	box-shadow: none;
	border-radius: 16px;
}

.default-filter-container {
	background-color: $table-head-bg;
	border: 1px solid $border-color;
	border-radius: 16px;

	&.grouped {
		border-radius: 16px 16px 0px 0px;
		border-bottom: none;
	}

	.default-filter-body {
		padding: 24px 32px 32px 32px;
	}

	.default-filter-footer {
		border-top: 1px solid $border-color;
		padding: 14px 32px;
		
		p {
			text-transform: uppercase;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			color: $headings-color;
			margin: 0;
		}
	}
}

.form-card {
	box-shadow: none;
	border: 1px solid $border-color;
	border-radius: 10px;

	.card-body {
		padding: 40px;
		padding-bottom: 50px;
	}

}

.search-form {
	label {
		font-size: 14px;
	}
	input {
		min-height: 30px;
		height: 30px;
	}

	.transparent-input {
		color: $text-muted !important;
		font-size: 10px !important;
		
		&:focus {
			box-shadow: none;
		}
	}
}

.swal2-title {
	margin: 0 !important;
	padding: 0 32px !important;
	margin-bottom: 6px !important;
	color: $headings-color !important;
	font-size: 21px !important;
	font-weight: 500 !important;
}

.swal2-html-container {
	margin: 0 !important;
	padding: 0 32px !important;
	margin-bottom: 4px !important;
	color: $body-color !important;
	font-size: 14px !important;
}

.swal2-icon.swal2-warning {
	border-color: $red !important;
    color: $red !important;
	margin-bottom: 18px !important;
}

.swal2-actions {
	flex-direction: row-reverse;
}

.default-dropdown {
	.dropdown-menu {
		.dropdown-item {
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $headings-color;

			.dot {
				width: 15px;
				height: 15px;
				border-radius: 50%;
				background: $success;
				vertical-align: middle;
				display: inline-block;
			}
		}
	}
}

.default-modal {
	.modal-header {
		background: $primary;

		.modal-title {
			color: $white;
		}
	}
}

.supervisor-card {
	background: rgba(186, 191, 199, 0.12);
	border-radius: 8px;
	margin: 0 16px;
	margin-bottom: 38px;
	margin-top: -140px;

	.card-body {
		padding: 24px 32px 16px 14px;
	}

	.card-footer {
		padding: 0;
		padding-bottom: 8px;

		button {
			width: 100%;
			padding: 10px 0;
			text-align: center;

			background: none;
			border: none;

			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			color: $secondary;
		}
	}
}

.default-scroll-container {
	overflow: auto;
    // Firefox scrollbar
    scrollbar-width: thin;
    scrollbar-color: $text-muted transparent;
	
    &::-webkit-scrollbar {
        width: 4px;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $text-muted;
        border-radius: 10px;
    }
}